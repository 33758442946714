import React from 'react'
import styled from 'styled-components'
import Root from './Root'
import { ApolloProvider } from 'react-apollo'
import client from 'src/apollo'
import { useWhiteLabel } from 'src/white-label'

const AppContainer = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: flex-start;
  flex-flow: column;
`

const App = () => {
  const { versionCode } = useWhiteLabel()
  return (
    <ApolloProvider client={client(versionCode)}>
      <AppContainer>
        <Root />
      </AppContainer>
    </ApolloProvider>
  )
}

export default App
