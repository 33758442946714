import { SERVER_GATEWAY } from 'src/config'
import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { createHttpLink } from 'apollo-link-http'
import { onError } from 'apollo-link-error'
import { authLink, localeLink, errorLink } from './links'
import { ApolloLink } from 'apollo-link'

const makeLink = (versionCode: string) => {
  const httpLink = createHttpLink({ uri: SERVER_GATEWAY })
  const links = [
    authLink(versionCode),
    localeLink,
    errorLink,
    genError,
    httpLink,
  ]
  return ApolloLink.from(links)
}

export const genError = onError(
  ({ response, graphQLErrors, networkError, operation, forward }) => {
    if (operation.getContext().handlesErrors) {
      return forward(operation)
    }

    if (graphQLErrors || networkError) {
      if (response && response.errors) {
        console.log(`Error making Apollo request`, response.errors)
        response.errors = undefined
      }
      return forward(operation)
    }
  }
)

const cache = new InMemoryCache()
const client = (versionCode: string) => {
  const link = makeLink(versionCode)
  return new ApolloClient({ link, cache })
}

export default client
